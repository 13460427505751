<template>
  <div
    style="height: 85vh; overflow: hidden; overflow-y: scroll; width: 100%;position: relative;"
  >
    <!-- <div class="bor_b flex mat_30" style="line-height: 50px; font-size: 14px; cursor: pointer;">
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(1)'>
        <span :class="{main_text_color: menu == 1}">全部</span>
        <div :class="{demand_nav: menu == 1}"></div>
      </div>
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(2)'>
        <span :class="{main_text_color: menu == 2}">进行中</span>
        <div :class="{demand_nav: menu == 2}"></div>
      </div>
      <div style='color: #808080;' @click='changeTags(3)'>
        <span :class="{main_text_color: menu == 3}">已完成</span>
        <div :class="{demand_nav: menu == 3}"></div>
      </div>
    </div> -->

    <el-tabs v-model="activeName" @tab-click="changeTags(activeName)">
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="进行中" name="2"></el-tab-pane>
      <el-tab-pane label="已完成" name="3"></el-tab-pane>
    </el-tabs>
    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="80px" class="flex">
        <div class="flex aline_center center wrap">
          <div style="width: 30%">
            <el-form-item label="搜索：">
              <el-input
                v-model="form.keyword"
                placeholder="请输入需求概述"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 30%">
            <el-form-item label="拟融资额度（万元）：" label-width="180px">
              <el-input
                v-model="form.min_quota"
                placeholder="请输入"
              ></el-input>
              <span style="margin: 0 20px">至</span>
              <el-input
                v-model="form.max_quota"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </div>
          <div style="width: 40%">
            <el-form-item label="申请时间：" label-width="180px">
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="选择日期"
                  v-model="form.startTime"
                  style="width: 100%"
                  @input="clearStartTime"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="选择日期"
                  v-model="form.endTime"
                  style="width: 100%"
                  @input="clearEndTime"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </div>
          <div style="width:30%">
            <el-form-item label="资金用途：">
              <el-select
                v-model="form.capital_comment"
                placeholder="请选择"
                clearable
                @clear="capital_comment = ''"
                style="width:100%"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in opaction1"
                  :key="index"
                  @click.native="setCapital(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div style="width: 30%;">
            <el-form-item label="可提供担保方式：" label-width="180px">
              <el-select
                v-model="form.guarantee"
                placeholder="请选择"
                clearable
                @clear="guarantee = ''"
                style="width:100%"
              >
                <el-option
                  :value="item.cate_name"
                  v-for="(item, index) in opaction3"
                  :key="index"
                  @click.native="setCate(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>

          <div style="margin-left: 61px;">
            <el-form-item label="需求状态：" label-width="120px">
              <el-select
                v-model="form.status"
                placeholder="请选择"
                clearable
                @clear="status = ''"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in opaction2"
                  :key="index"
                  @click.native="setStatus(item)"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button type="primary" @click.native="$throttle(searchData)"
                >搜索</el-button
              >
              <el-button
                type="primary"
                @click.native="$throttle(toFinancingneeds)"
                >新增</el-button
              >
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <el-table
      :data="listsItem"
      style="width:auto"
      
      :header-cell-style="{ background: '#e5f3ff', color: '#1A7FD7' }"
    >
      <el-table-column prop="mark" label="编号"> </el-table-column>
      <el-table-column prop="explain" label="需求概述"> </el-table-column>
      <el-table-column prop="quota" label="拟融资额度（万元）" class-name="red">
      </el-table-column>
      <el-table-column prop="capital_comment" label="资金用途">
      </el-table-column>
      <el-table-column prop="capital_time" label="资金到位时间" width='120px'>
      </el-table-column>
      <el-table-column prop="cate_name" label="可提供担保方式" width='80px'>
      </el-table-column>
      <el-table-column prop="create_time" label="申请时间"> </el-table-column>
      <el-table-column prop="status" label="状态" width='80px'>
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1"> 未匹配</span>
          <span v-if="scope.row.status == 3"> 待预授信</span>
          <span v-if="scope.row.status == 4"> 待授信</span>
          <span v-if="scope.row.status == 5"> 拒绝授信</span>
          <span v-if="scope.row.status == 6"> 已授信</span>
          <span v-if="scope.row.status == 7"> 已完成</span>
          <span v-if="scope.row.status == 8" style="color:red;!important"> 待上传</span>

        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" label-width="200px">
        <template slot-scope="scope">
          <span
            class="success_font"
            @click="toAgencydetails(scope.row.finance_id)"
            >查看对接机构</span
          >
          <span
            v-if="scope.row.status == 5"
            class="not_font"
            @click="lookWhy(scope.row.comment)"
            >查看原因</span
          >
          <!--    -->
            <span
          v-if="scope.row.status == 8"
            class="success_font"
            @click="showDialog(scope.row)"
            >待上传</span
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
      <div class="financialproducts_list_footer">
        <div class="financialproducts_list_mar">
          <app-mypaginations
            :pagSize="paginations"
            :allData="goodList"
            @setHandleCurrent="handleCurrent"
            @setHandleSize="handleSize"
            @setPage="setPage"
            @setSize="setSize"
          />
      </div>
    </div>
    <el-dialog
  title="企业上传文件"
  :visible.sync="dialogVisible"
  width="30%"
 >
 <!-- 上传 -->
 <el-form>
	<el-form-item :label="item.explain_name"  v-for="(item,index) in file_type" :key="index">
						<div class="webproductapply_form_document">
							
							<div v-if="!(item.explain_name===null)">
								<el-upload ref='uploadfilesdocument' :data="{'index':index,'id':item.id}" 
								class='webproductapply_upload' action="#" :multiple="false" :limit="1" name="file"
								:before-upload="beforeAvatarUpload" :on-remove="delFile" :on-exceed='handleExceed' 
								:http-request="httprequest">
								<el-button size="small" type="primary" >选择文件</el-button>
								</el-upload>
							</div>
						
						</div>
					</el-form-item>
          </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="uploadFile()">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';

export default {
  data() {
    return {
      menu: 1,
      form: {
        keyword: '',
        min_quota: '',
        max_quota: '',
        guarantee: '',
        startTime: '',
        endTime: '',
        status: '',
        capital_comment: '',
      },
      opaction1: [
        {
          value: '产品研发',
          id: 1,
        },
        {
          value: '资金周转',
          id: 2,
        },
        {
          value: '其他',
          id: 3,
        },
      ],
      opaction2: [
        {
          value: '待匹配',
          id: 1,
        },
        {
          value: '待预授信',
          id: 2,
        },
        {
          value: '待授信',
          id: 3,
        },
        {
          value: '拒绝授信',
          id: 4,
        },
        {
          value: '已授信',
          id: 5,
        },
        {
          value: '待上传',
          id: 8,
        },
      ],
      opaction3: [],
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      name: '',
      goodList: [],
      capital_comment: '',
      guarantee: '',
      status: '',
      count: 0,
      listsItem: [],
      activeName: '1',
      dialogVisible:false,
      file_type:[],
      flagArr:[],
      fileArr:[],
      idd:'',
      demandId:''

    };
  },
  created() {
    this.comDemandApi();
  },
  methods: {
    showDialog(val){
            // let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };
      this.idd=val.id
      this.dialogVisible=true
      this.$get('/file_require_list',{demand_id:val.id,token:localStorage.eleToken})
      .then(res => {
					this.file_type=res.data.result
				console.log();
					
				})



    },
    getproduct() {
				
			},
    lookWhy(comment) {
      this.$confirm(comment, '原因', {
        showConfirmButton: false,
        showCancelButton: false,
        closeOnClickModal: true,
      });
    },
    toFinancingneeds() {
      let data = { token: localStorage.eleToken ? localStorage.eleToken : '' };

      this.$post('/vali', data).then((res) => {
        if (res.data.status == 1) {
          this.adopt = res.data.result.adopt;

          if (res.data.result.type == 1) {
            if (this.adopt == 0) {
              this.$message({
                message: '实名认证审核中',
                type: 'info',
              });
            } else if (this.adopt == 1) {
              this.$router.push('/financingneeds');

              this.point = index;
            } else if (res.data.result.adopt == 2) {
              this.$confirm('实名认证不通过，是否重新提交？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  // this.$router.push({path: '/enterprisecertification'})
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else if (res.data.result.adopt == 3) {
              this.$confirm('还没有实名认证，是否前去完善？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => {
                  // this.$router.push('/enterprisecertification')
                  location.href = 'http://sso.smeqh.cn:9000/realname/index';
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消',
                  });
                });
            } else {
              return false;
            }
          }

          if (res.data.result.type == 2) {
            localStorage.clear();

            this.$router.replace('/login');
          }
        }
      });
    },
    toAgencydetails(id) {
      // console.log(id)
      if (!id) {
        this.$message({
          message: '未匹配机构，无法查看',
          type: 'error',
          duration: 1500,
        });
      } else {
        this.$router.push({ path: '/agencydetails', query: { ids: id } });
      }
    },
    changeTags(num) {
      this.menu = num;

      this.searchData();
    },
    searchData() {
      this.paginations.page_index = 1;

      this.comDemandApi();
    },
    clearEndTime(value) {
      if (value == null) {
        this.form.endTime = '';
      }
    },
    clearStartTime(value) {
      if (value == null) {
        this.form.startTime = '';
      }
    },
    setCapital(item) {
      this.capital_comment = item.id;
    },
    setCate(item) {
      this.guarantee = item.id;
    },
    setStatus(item) {
      this.status = item.id;
    },
    getClass() {
      this.$get('/pro_cate', { type: 2 }).then((res) => {
        this.opaction3 = !res.data.result ? [] : res.data.result;
      });
    },
    setPage(data) {
      this.paginations.page_index = data;
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.goodList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
    },
    comDemandApi() {
      let data = {
        token: localStorage.eleToken ? localStorage.eleToken : '',
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        keyword: this.form.keyword,
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        guarantee: this.guarantee,
        startTime:
          this.form.startTime == '' ? '' : this.$turnTime(this.form.startTime),
        endTime:
          this.form.endTime == '' ? '' : this.$turnTime(this.form.endTime),
        status: this.status,
        capital_comment: this.capital_comment,
        group: this.menu,
      };

      this.$get('/com_demand', data).then((res) => {
        this.goodList = res.data.result ? res.data.result : [];
        this.count = res.data.count ? res.data.count : 0;

        this.resetPaginations();
      });
    },
    // 文件上传
    handleExceed() {
				this.$message({
					message: '最大上传数量为1张!',
					type: 'warning',
					duration: 1500
				})
			},
  //  进行filr组成
      httprequest(load) {
        				let formdata = new FormData()
        				formdata.append('file', load.file)

    this.$post('/uploadFile', formdata).then((res) => {
          if(res.status===200){
            console.log(res.data);
            this.$set(this.fileArr,this.fileArr.length,{
              			path: res.data.address,
                    file_name: res.data.file_name,
                    name: res.data.name,
                    explain_id:load.data.id,
                    size:res.data.file_size
            })
          //  this.fileArr.push(res.data) 
            // this.dialogVisible=false
          }
      })
    //     // this.fileArr.push(load.file)
    //     console.log(load.data,'this.fileArr');
     
        // console.log(this.fileArr,'this.fileArr');
			// 	let formdata = new FormData()
			// 	let url = 'http://zk.zhenchangkj.com/v1/demand_file_add'
			// 	formdata.append('file', load.file)

			// 	axios.post(url, {file:formdata,token:localStorage.eleToken,id:this.idd}, {
			// 		timeout: 600 * 1000,
				
			// 	}).then((res) => {
			// 		this.formLabelAlign.uploadImg1 = res.data.address
			// 		let fileObjList= {
			// 			path: res.data.address,
			// 			file_name: res.data.file_name,
			// 			file_size: res.data.file_size,
			// 			name: res.data.name,
			// 			type: this.flagArr[load.data.index].type
			// 		}
					
			// 		this.fileObj.push(fileObjList)
			// 		this.$message.success('文档上传成功！')
			// 		console.log(this.flagArr[0].documentList);
			// 	}).catch((err) => {
			// 		this.$message.error('文档上传超时，请重新上传！')
			// 	})
			},
      uploadFile(){
if(this.fileArr.length===0){
this.$message.warning('请开始上传')
}else{
      let data = { file:this.fileArr,token: localStorage.eleToken ? localStorage.eleToken : '',id:this.idd,status:4 };
    this.$post('/demand_file_add', data).then((res) => {
          if(res.status===200){
            this.$message.success('添加成功！')
     this.dialogVisible=false
         this.comDemandApi();

          }else{
            this.$message.error('添加失败，请联系后端人员')
          }
      })
}
   
   
      },
      delFile(load){
        console.log(load);
        // this.fileArr.forEach(item=>{
        // if(load.name===item.name){
        //   this.fileArr.splice(item)
        // }
        //   })
        //  this.fileArr.splice(this.fileArr,1)
      },
     
      beforeAvatarUpload(file) {
        console.log(file,'filebefore');
				const isLt2M = file.size / 1024 / 1024 < 200;
				if (!isLt2M) {
					this.$message({
						message: '上传文件大小不能超过 200MB!',
						type: 'warning'
					})
				}

				return isLt2M
			},
      handleRemove(){
      
				
			},
  },
  mounted() {
this.resetPaginations();
    this.getClass();
  },
  components: {
    'app-mypaginations': MyPaginations,
  },
};
</script>

<style scoped>
.demand_nav {
  width: 40px;
  height: 4px;
  background: #0d8eff;
  border-radius: 2px;
}

.el-form-item__content {
  display: flex;
}

/*分页*/
.financialproducts_list_reportscreen {
  position: absolute;
  bottom: 0.5%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.financialproducts_list_footer {
  min-width: 462px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
}
.financialproducts_list_mar {
  width: 100%;
}
.el-pagination {
  display: flex;
}
.el-pagination .el-select .el-input {
  height: 100%;
}
.el-select {
  height: 100%;
}
.el-pagination__jump {
  display: flex !important;
}

/deep/.red {
  color: #ff9d00;
}
/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}
</style>
